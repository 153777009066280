import React from 'react'

import s from  './Footer.module.css';

const Footer = () => {
  return (
    <footer className={s.footer}>
      <p>copyright &copy; 2021 - Kimieartesanal</p>
    </footer>
  )
}

export default Footer;