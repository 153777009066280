import semanal from '../../img/semanal/1.jpg';
import semanal2 from '../../img/semanal/2.jpg';
import universitaria from  '../../img/universitaria/1.jpg';
import universitaria2 from '../../img/universitaria/2.jpg';
import universitaria3 from '../../img/universitaria/2.jpg';
import universitaria4 from '../../img/universitaria/4.jpg';
import universitaria5 from '../../img/universitaria/5.jpg';
import docente from '../../img/docente/1.jpg';
import docente2 from '../../img/docente/2.jpg';
import rayado from '../../img/rayado/1.jpg';
import rayado2 from '../../img/rayado/2.jpg';
import cuadriculado from '../../img/cuadriculado/1.jpg';
import cuadriculado2 from '../../img/cuadriculado/2.jpg';
import punteado from '../../img/punteado/1.jpg';
import punteado2 from '../../img/punteado/2.jpg';
import liso from '../../img/liso/1.jpg';
import liso2 from '../../img/liso/2.jpg';
import kakeibo from '../../img/kakeibo/1.jpg';
import kakeibo2 from '../../img/kakeibo/2.jpg';
import kakeibo3 from '../../img/kakeibo/3.jpg';
import kakeibo4 from '../../img/kakeibo/4.jpg';
import kakeibo5 from '../../img/kakeibo/5.jpg';
import baby from '../../img/baby/1.jpg';
import baby2 from '../../img/baby/2.jpg';
import sanitariaClasica from '../../img/sanitariaclasica/1.jpg';
import sanitariaClasica2 from '../../img/sanitariaclasica/2.jpg';
import sanitariaClasica3 from '../../img/sanitariaclasica/3.jpg';
import sanitariaClasica4 from '../../img/sanitariaclasica/4.jpg';
import sanitariaClasica5 from '../../img/sanitariaclasica/5.jpg';
import sanitariaClasica6 from '../../img/sanitariaclasica/6.jpg';
import sanitariaClasica7 from '../../img/sanitariaclasica/7.jpg';
import sanitariaClasica8 from '../../img/sanitariaclasica/8.jpg';
import sanitariaDeluxe from '../../img/sanitariadeluxe/1.jpg';
import sanitariaDeluxe2 from '../../img/sanitariadeluxe/2.jpg';
import nihon from '../../img/nihon/1.jpg';
import nihon2 from '../../img/nihon/2.jpg';
import miryoku from '../../img/miryoku/1.jpg';
import miryoku2 from '../../img/miryoku/2.jpg';
import tradicionales from '../../img/tradicionales/1.jpg';
import tradicionales2 from '../../img/tradicionales/2.jpg';
import personalizadas from '../../img/personalizadas/1.jpg';
import personalizadas2 from '../../img/personalizadas/2.jpg';
import portada1 from '../../img/portada1.jpg';
import portada2 from '../../img/portada2.jpg';
import portada3 from '../../img/portada3.jpg';
import portada4 from '../../img/portada4.jpg';
import logo from '../../img/logo.png';

const Images = {
  semanal: [semanal],
  semanalModal: [semanal2],
  universitaria: [universitaria],
  universitariaModal: [universitaria2,universitaria3,universitaria4,universitaria5],
  docente: [docente],
  docenteModal: [docente2],
  rayado: [rayado],
  rayadoModal: [rayado2],
  cuadriculado: [cuadriculado],
  cuadriculadoModal: [cuadriculado2],
  punteado: [punteado],
  punteadoModal: [punteado2],
  liso: [liso],
  lisoModal: [liso2],
  kakeibo: [kakeibo],
  kakeiboModal: [kakeibo2,kakeibo3,kakeibo4,kakeibo5],
  baby: [baby],
  babyModal: [baby2],
  clasica: [sanitariaClasica],
  clasicaModal: [sanitariaClasica2,sanitariaClasica3,sanitariaClasica4,sanitariaClasica5,sanitariaClasica6,sanitariaClasica7,sanitariaClasica8],
  deluxe: [sanitariaDeluxe],
  deluxeModal: [sanitariaDeluxe2],
  nihon: [nihon],
  nihonModal: [nihon2],
  miryoku: [miryoku],
  miryokuModal: [miryoku2],
  tradicionales: [tradicionales],
  tradicionalesModal: [tradicionales2],
  personalizadas: [personalizadas],
  personalizadasModal: [personalizadas2],
  portada: [portada1, portada2, portada3, portada4],
  logo: [logo]
};

export default Images;