import React from 'react';
import s from  './Anouncement.module.css';

const Anouncement= () => {
  return (
    <div className={s.anouncement}>
      <p>
        Todos los meses promociones nuevas! Seguíme en instagram para mas novedades!
      </p>
    </div>
  )
}

export default Anouncement;